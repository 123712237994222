import CodeExample from "components/docs/code-examples/CodeExample";
import { Link } from "gatsby";
import React from "react";
import { Col, Row } from "react-bootstrap";
import styled from "styled-components";
import { StaticImage } from "gatsby-plugin-image";
import DocumentationSection from "../../components/docs/DocumentationSection";
import HeaderBar from "../../components/docs/layout/HeaderBar";
import CrossGuideLinks from "../../components/docs/navigation/CrossGuideLinks";
import DocsHelmet from "../../components/docs/shared-components/DocsHelmet";
import { HTTPMethodBadge } from "../../components/docs/shared-components/MergeBadges";
import { StaticPageContainer } from "../../components/docs/shared-components/PageContainers";
import { HTTPMethod, RouterLocation } from "../../types/types";
import { PricingInfoCard } from "components/docs/shared-components/NoticeCards";

const HTTPMethodBadgeWithStyle = styled(HTTPMethodBadge)`
  font-size: 16px;
  line-height: 26px;
`;

const Title = styled.div`
  font-weight: 600;
  font-size: 16px;
  line-height: 26px;
`;

interface Props {
  location: RouterLocation;
}
function IdempotencyPage({ location }: Props) {
  const title = "Idempotency";
  const description = "Learn how to make idempotent POST requests with automatic retries";
  return (
    <StaticPageContainer style={{ paddingBottom: "0" }}>
      <DocsHelmet title={title} description={description} />
      <HeaderBar title={title} subtitle={description}>
        <PricingInfoCard className="mb-9 mt-0">
          Idempotent operations are currently in beta, reach out to your account representative or{" "}
          <a href="mailto: support@merge.dev">contact us</a> for more information.
        </PricingInfoCard>
      </HeaderBar>
      <DocumentationSection title="Overview">
        <>
          <p>
            For POST operations, Merge supports idempotency for safely retrying requests without
            accidentally performing the same operation twice. To perform an idempotent request,
            include an <code>Idempotency-Key</code> header in POST requests to ensure the same
            request is not processed more than once. It's recommended to use a V4 UUID, but any
            sufficiently unique string will work. The idempotency log is stored for 24 hours before
            it expires.
          </p>
          <ul>
            <li className=" ml-2">
              If the key is unique, Merge will store a log of the request, including the hashed
              request body, status code, and response body
            </li>
            <li className=" ml-2">
              If the same key is reused with identical parameters, Merge will return the previously
              stored response
            </li>
            <li className=" ml-2">
              If the key is reused with different parameters, the API will return a 400 error
              indicating an invalid reuse
            </li>
          </ul>
          <p>
            Retries on failed asynchronous requests will automatically follow the rate-limiting and
            retry mechanisms in the API. This approach allows for more resilient, scalable
            integrations, minimizing duplicate requests and supporting asynchronous processing for
            heavier tasks.
          </p>
        </>
      </DocumentationSection>

      <CrossGuideLinks location={location} style={{ marginTop: "12px" }} />
    </StaticPageContainer>
  );
}

export default IdempotencyPage;
